// 
// user.scss
// Use this to write your custom SCSS
//

#welcome {
  .card {
    background: rgba($gray-300, .83);
    .display-3 {
      font-size: 3.25rem;
    }
  }
}

#users {
  .display-5 {
    font-size: 2.3rem;
  }
}

.mw-175 {
  min-width: 175px;
}

.bg-gray-100 {
  background: $gray-100;
}
.font-size-sm {
  font-size: 15px;
}

.select-wrap {
  position: relative;
  &:after {
    content: "";
    position: absolute;
    right: 20px;
    top: 22px;
    border-width: 8px 6px;
    border-style: solid;
    border-color: $black transparent transparent;
    pointer-events: none;
  }
  select {
    &:invalid {
      color: $gray-600;
    }
  }
  [disabled] {
    color: $gray-600;
  }
  option {
    color: $gray-900;
  }
}
.blockquote-footer {
  opacity: .7;
}

.object-fit {
  object-fit: cover;
}

.bg-nav-gradient {
  background: #f6f8fa; 
  background: -webkit-linear-gradient(to bottom, #f9fbfd, #f6f8fa);
  background: linear-gradient(to bottom, #f9fbfd, #f6f8fa);
  box-shadow: 0 1px 4px #00000038;
}


.icon-sm > svg {
  width: 100%;
  height: 100%;
}

.footer-hipaa {
  max-width: 80%;
}

.round-badge {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: block;
  margin-right: 10px;
}
.dropdown {
  &[data-select="select"] {
    text-align: left;
    position: relative;
    .form-select {
      display: flex;
      text-align: left;
      align-items: center;
      padding-right: 3.2rem;
    }
    .select-value {
      flex-grow: 1;
    }
    .dropdown-menu {
      padding: .8rem 1.75rem;
    }
    .dropdown-item {
      margin-top: 0;
      padding: .6rem 1.75rem;
      cursor: pointer;
      &.active {
        background: $gray-100;
      }
    }
  }
}

.w320 {
  width: 320px;
}
.total {
  font-size: $h3-font-size;
  @include media-breakpoint-down(md) {
    font-size: $h4-font-size;
  }
}

.range {
  .ranger-value-wrap {
    position:relative;
    width:100%;
    .ranger-value-info-wrap {
      position: absolute;
      font-weight: 500;
      top: -40px;
      transform-origin: bottom;
      transition: transform 0.3s ease-in-out;
      z-index: 2;
      text-align: center;
      background: $primary;
      color: $white;
      font-size: .75rem;
      border-radius: $border-radius;
      padding: 5px 9px;
      margin-left: -4px;
      white-space: nowrap;
      @include media-breakpoint-down(md) {
        margin-left: 0;
        transform: translateX(-50%);
      }
    }
  }

  input[type=range] {
    height: 8px;
    -webkit-appearance: none;
    width: 100%;
    background: rgba($primary, .3);
    border-radius: 5px;
    outline: none !important;
    &::-moz-range-track {
      width: 100%;
      height: 11px;
      cursor: pointer;
      animate: 0.2s;
      border-radius: 1px;
      border: none;
    }
    &::-moz-range-thumb {
      border: none;
      height: 26px;
      width: 26px;
      border-radius: 15px;
      background: $primary;
      cursor: pointer;
    }
    &::-moz-range-progress {
      height: 10px;
      background: $primary;
      border-radius: 5px 1px 1px 5px;
    }
    &::-webkit-slider-runnable-track {
      width: 100%;
      height: 8px;
      cursor: pointer;
      animate: 0.2s;
      border-radius: 1px;
      border: none;
    }
    &::-webkit-slider-thumb {
      border: none;
      height: 26px;
      width: 26px;
      border-radius: 15px;
      background: $primary;
      cursor: pointer;
      -webkit-appearance: none;
      margin-top: -8px;
    }
  }
}

.video-wrap-phone {
  video {
    position: absolute;
    left: 9%;
    top: 8%;
    right: 5%;
    bottom: 11%;
    display: block;
    width: 80%;
  }
}

#unlimitedScans {
  transition: opacity .7s cubic-bezier(.25,.46,.45,.94);
}